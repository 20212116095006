
// @ts-nocheck


export const localeCodes =  [
  "pl",
  "cz",
  "pl-en",
  "ro",
  "de"
]

export const localeLoaders = {
  "pl": [{ key: "../locale/langs/pl-PL.json", load: () => import("../locale/langs/pl-PL.json" /* webpackChunkName: "locale__opt_atlassian_pipelines_agent_build_ui_locale_langs_pl_PL_json" */), cache: true }],
  "cz": [{ key: "../locale/langs/cs-CZ.json", load: () => import("../locale/langs/cs-CZ.json" /* webpackChunkName: "locale__opt_atlassian_pipelines_agent_build_ui_locale_langs_cs_CZ_json" */), cache: true }],
  "pl-en": [{ key: "../locale/langs/en-US.json", load: () => import("../locale/langs/en-US.json" /* webpackChunkName: "locale__opt_atlassian_pipelines_agent_build_ui_locale_langs_en_US_json" */), cache: true }],
  "ro": [{ key: "../locale/langs/ro-RO.json", load: () => import("../locale/langs/ro-RO.json" /* webpackChunkName: "locale__opt_atlassian_pipelines_agent_build_ui_locale_langs_ro_RO_json" */), cache: true }],
  "de": [{ key: "../locale/langs/de-DE.json", load: () => import("../locale/langs/de-DE.json" /* webpackChunkName: "locale__opt_atlassian_pipelines_agent_build_ui_locale_langs_de_DE_json" */), cache: true }]
}

export const vueI18nConfigs = [
  () => import("../locale/vue-i18n.ts?hash=20977ec1&config=1" /* webpackChunkName: "__locale_vue_i18n_ts_20977ec1" */)
]

export const nuxtI18nOptions = {
  "experimental": {
    "localeDetector": "",
    "switchLocalePathLinkSSR": false,
    "autoImportTranslationFunctions": false
  },
  "bundle": {
    "compositionOnly": true,
    "runtimeOnly": false,
    "fullInstall": true,
    "dropMessageCompiler": false
  },
  "compilation": {
    "jit": true,
    "strictMessage": true,
    "escapeHtml": false
  },
  "customBlocks": {
    "defaultSFCLang": "json",
    "globalSFCScope": false
  },
  "vueI18n": "./locale/vue-i18n.ts",
  "locales": [
    {
      "code": "pl",
      "iso": "pl-PL",
      "lang": "pl",
      "title": "Polska / Poland",
      "storeId": "default",
      "storePrefix": "pl_pl",
      "country": "PL",
      "currency": "PLN",
      "freeShippingThreshold": 300,
      "magentoMainCategory": "2",
      "specificGeolocationCountryCodes": [],
      "storifyme": 8007,
      "files": [
        "locale/langs/pl-PL.json"
      ]
    },
    {
      "code": "cz",
      "iso": "cs-CZ",
      "lang": "cs",
      "title": "Česká republika / Czech Republic",
      "storeId": "cs_cz",
      "storePrefix": "cs_cz",
      "country": "CZ",
      "currency": "CZK",
      "freeShippingThreshold": 2000,
      "magentoMainCategory": "23",
      "specificGeolocationCountryCodes": [
        "cz",
        "sk"
      ],
      "files": [
        "locale/langs/cs-CZ.json"
      ]
    },
    {
      "code": "pl-en",
      "iso": "en-US",
      "lang": "en",
      "title": "International",
      "storeId": "pl_en",
      "storePrefix": "pl_en",
      "country": "PL",
      "currency": "PLN",
      "freeShippingThreshold": 300,
      "magentoMainCategory": "2",
      "specificGeolocationCountryCodes": [],
      "files": [
        "locale/langs/en-US.json"
      ]
    },
    {
      "code": "ro",
      "iso": "ro-RO",
      "lang": "ro",
      "title": "România / Romania",
      "storeId": "ro_ro",
      "storePrefix": "ro_ro",
      "country": "RO",
      "currency": "RON",
      "freeShippingThreshold": 200,
      "magentoMainCategory": "25",
      "specificGeolocationCountryCodes": [
        "ro"
      ],
      "files": [
        "locale/langs/ro-RO.json"
      ]
    },
    {
      "code": "de",
      "iso": "de-DE",
      "lang": "de",
      "title": "Deutschland / Germany",
      "storeId": "de_de",
      "storePrefix": "de_de",
      "country": "DE",
      "currency": "EUR",
      "freeShippingThreshold": 2000,
      "magentoMainCategory": "4",
      "specificGeolocationCountryCodes": [
        "de"
      ],
      "files": [
        "locale/langs/de-DE.json"
      ]
    }
  ],
  "defaultLocale": "",
  "defaultDirection": "ltr",
  "routesNameSeparator": "___",
  "trailingSlash": false,
  "defaultLocaleRouteNameSuffix": "default",
  "strategy": "prefix",
  "lazy": true,
  "langDir": "locale/langs/",
  "detectBrowserLanguage": false,
  "differentDomains": false,
  "baseUrl": "",
  "dynamicRouteParams": false,
  "customRoutes": "config",
  "pages": {},
  "skipSettingLocaleOnNavigate": false,
  "types": "composition",
  "debug": false,
  "parallelPlugin": false,
  "i18nModules": []
}

export const normalizedLocales = [
  {
    "code": "pl",
    "iso": "pl-PL",
    "lang": "pl",
    "title": "Polska / Poland",
    "storeId": "default",
    "storePrefix": "pl_pl",
    "country": "PL",
    "currency": "PLN",
    "freeShippingThreshold": 300,
    "magentoMainCategory": "2",
    "specificGeolocationCountryCodes": [],
    "storifyme": 8007,
    "files": [
      {
        "path": "locale/langs/pl-PL.json"
      }
    ]
  },
  {
    "code": "cz",
    "iso": "cs-CZ",
    "lang": "cs",
    "title": "Česká republika / Czech Republic",
    "storeId": "cs_cz",
    "storePrefix": "cs_cz",
    "country": "CZ",
    "currency": "CZK",
    "freeShippingThreshold": 2000,
    "magentoMainCategory": "23",
    "specificGeolocationCountryCodes": [
      "cz",
      "sk"
    ],
    "files": [
      {
        "path": "locale/langs/cs-CZ.json"
      }
    ]
  },
  {
    "code": "pl-en",
    "iso": "en-US",
    "lang": "en",
    "title": "International",
    "storeId": "pl_en",
    "storePrefix": "pl_en",
    "country": "PL",
    "currency": "PLN",
    "freeShippingThreshold": 300,
    "magentoMainCategory": "2",
    "specificGeolocationCountryCodes": [],
    "files": [
      {
        "path": "locale/langs/en-US.json"
      }
    ]
  },
  {
    "code": "ro",
    "iso": "ro-RO",
    "lang": "ro",
    "title": "România / Romania",
    "storeId": "ro_ro",
    "storePrefix": "ro_ro",
    "country": "RO",
    "currency": "RON",
    "freeShippingThreshold": 200,
    "magentoMainCategory": "25",
    "specificGeolocationCountryCodes": [
      "ro"
    ],
    "files": [
      {
        "path": "locale/langs/ro-RO.json"
      }
    ]
  },
  {
    "code": "de",
    "iso": "de-DE",
    "lang": "de",
    "title": "Deutschland / Germany",
    "storeId": "de_de",
    "storePrefix": "de_de",
    "country": "DE",
    "currency": "EUR",
    "freeShippingThreshold": 2000,
    "magentoMainCategory": "4",
    "specificGeolocationCountryCodes": [
      "de"
    ],
    "files": [
      {
        "path": "locale/langs/de-DE.json"
      }
    ]
  }
]

export const NUXT_I18N_MODULE_ID = "@nuxtjs/i18n"
export const parallelPlugin = false
export const isSSG = false

export const DEFAULT_DYNAMIC_PARAMS_KEY = "nuxtI18n"
export const DEFAULT_COOKIE_KEY = "i18n_redirected"
export const SWITCH_LOCALE_PATH_LINK_IDENTIFIER = "nuxt-i18n-slp"
