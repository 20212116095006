export function parseDate(date: string | undefined | null): Date | null {
    try {
        if (!date) return null;
        const dateParsed = new Date(date);
        // check if date is valid
        if (Number.isNaN(dateParsed.getTime())) return null;
        return dateParsed;
    } catch (e) {
        return null;
    }
}
