import { reactive } from 'vue';
import { readonly } from 'zyme';

interface WindowSize {
    width: number;
    height: number;
}

let windowSize: WindowSize;

export function useWindowSize() {
    if (!windowSize) {
        windowSize = reactive({
            width: 0,
            height: 0,
        });

        // detect SSR context
        if (typeof window !== 'undefined') {
            window.addEventListener('resize', resize, { passive: true });
            resize();
        }
    }

    return readonly(windowSize);
}

function resize() {
    windowSize.height = window.innerHeight;
    windowSize.width = getWindowWidth();
}

function getWindowWidth() {
    const windowWidth = window.screen?.width;
    if (windowWidth && windowWidth < window.innerWidth) {
        return windowWidth;
    }
    return window.innerWidth;
}
