/**
 * Formats strings with placeholders, like:
 * 'product price {price}'
 * @param template template to format
 * @param params parameters to format with
 * @returns output string
 */
export function formatWith(template: string, params: Record<string, unknown>) {
    return template.replace(/{\s*(\w*)\s*}/gi, (match, key) => {
        if (params.hasOwnProperty(key)) {
            return String(params[key]);
        }

        return match;
    });
}

/**
 * Checks if two strings equal case-insensitive
 * @param str1 first string
 * @param str2 second string
 * @returns are strings equal case-insensitive
 */
export function equalsIgnoreCase(str1?: string, str2?: string) {
    return str1?.toLowerCase() === str2?.toLowerCase();
}

/**
 * Checks if string is a digit
 * @param c input string
 * @returns is string a digit
 */
export function isDigit(c: string) {
    return c >= '0' && c <= '9';
}

/**
 * convert first letter to capital letter
 * @returns is string
 */
export function capitalizeFirstLetter(text: string): string {
    if (!text.length) return '';
    const [first, ...rest] = text;
    return first.toUpperCase() + rest.join('');
}

/**
 * replace polish char diag to normal
 */

export function replacePolishLetters(text: string): string {
    const letters = ['ą', 'ć', 'ę', 'ł', 'ń', 'ó', 'ś', 'ź', 'ż'];
    const replacement = ['a', 'c', 'e', 'l', 'n', 'o', 's', 'z', 'z'];
    let input = text.toLocaleLowerCase();

    for (let i = 0; i < letters.length; ++i) {
        input = input.replaceAll(letters[i], replacement[i]);
    }
    return input;
}
