export type ShippingMethodType = keyof typeof shippingMethodCodes;

export const shippingMethodCodes = {
    inpost: 'flatrate',
    dhlPoland: 'dhlpoland',
    dhlWorldwide: 'dhlworldwide',
    dhlPickUpPoint: 'dhlpop',
    dhlCashOnDelivery: 'dhlcashondelivery',
    pharmalink: 'pharmalink',
    fxcouriers: 'fxcouriers',
    dpdCashOnDelivery: 'dpdcashondelivery',
    dpdPoland: 'dpd',
    dpdPickUpPoint: 'dpdpop',
};
