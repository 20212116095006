import { inject, provide, reactive, type UnwrapRef } from 'vue';

export interface ServiceConstructor<TParams extends unknown[], TService extends object> {
    (this: undefined, ...params: TParams): TService;
}

export interface ServiceDefinition<TParams extends unknown[], TService extends object> {
    readonly constructor: ServiceConstructor<TParams, TService>;
    readonly symbol: symbol;
}

export type ServiceOf<T> = T extends ServiceDefinition<any, infer TService>
    ? UnwrapRef<TService>
    : never;

export type ServiceParams<T> = T extends ServiceDefinition<infer TParams, any> ? TParams : never;

export function defineService<TParams extends unknown[], TService extends object>(
    service: ServiceConstructor<TParams, TService>
): ServiceDefinition<TParams, TService> {
    return {
        constructor: service,
        symbol: Symbol(),
    };
}

export function provideService<TParams extends unknown[], TService extends object>(
    service: ServiceDefinition<TParams, TService>,
    ...params: TParams
) {
    const instance = reactive(service.constructor.apply(undefined, params));
    provide(service.symbol, instance);
    return instance;
}

export function injectService<TParams extends unknown[], TService extends object>(
    service: ServiceDefinition<TParams, TService>
): UnwrapRef<TService>;
export function injectService<TParams extends unknown[], TService extends object>(
    service: ServiceDefinition<TParams, TService>,
    opts: { optional: boolean }
): UnwrapRef<TService> | null;
export function injectService<TParams extends unknown[], TService extends object>(
    service: ServiceDefinition<TParams, TService>,
    opts?: { optional: boolean }
): UnwrapRef<TService> | null {
    const instance = inject<UnwrapRef<TService> | null>(service.symbol, null);
    if (!instance) {
        if (opts?.optional) {
            return null;
        }

        throw new Error(`Service was not registered`);
    }

    return instance;
}
