import type { GraphQLFormattedError } from 'graphql';

export class GraphqlError extends Error {
    constructor(public readonly errors: GraphQLFormattedError[]) {
        super(errors[0]?.message ?? 'GraphQL error occured');
    }
}

export function isGraphQlError(error: unknown): error is GraphqlError {
    return error instanceof GraphqlError;
}
