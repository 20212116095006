import revive_payload_client_Frd8gASYWa from "/opt/atlassian/pipelines/agent/build/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import router_CrWB4n4PyO from "/opt/atlassian/pipelines/agent/build/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import components_plugin_KR1HBZs4kY from "/opt/atlassian/pipelines/agent/build/ui/.nuxt/components.plugin.mjs";
import unhead_3Bi0E2Ktsf from "/opt/atlassian/pipelines/agent/build/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import prefetch_client_5ZRYOjuei0 from "/opt/atlassian/pipelines/agent/build/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import i18n_huatSqtcYP from "/opt/atlassian/pipelines/agent/build/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import chunk_reload_client_kCUITUWXUN from "/opt/atlassian/pipelines/agent/build/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import configs_thizg2yOzO from "/opt/atlassian/pipelines/agent/build/ui/configs.ts";
import components_m1q6StQDkO from "/opt/atlassian/pipelines/agent/build/ui/plugins/components.ts";
import vendors_E1pw69vgqi from "/opt/atlassian/pipelines/agent/build/ui/plugins/vendors.ts";
import router_client_W01CLn04zL from "/opt/atlassian/pipelines/agent/build/ui/plugins/router.client.ts";
import pages_client_aLbDq82dK3 from "/opt/atlassian/pipelines/agent/build/ui/plugins/pages.client.ts";
import checkVersion_client_yt3aQHgowY from "/opt/atlassian/pipelines/agent/build/ui/plugins/checkVersion.client.ts";
import errors_client_8b2hJTi7xy from "/opt/atlassian/pipelines/agent/build/ui/plugins/errors.client.ts";
import accessibility_client_7eLzPBccuK from "/opt/atlassian/pipelines/agent/build/ui/plugins/accessibility.client.ts";
import sentry_client_shVUlIjFLk from "/opt/atlassian/pipelines/agent/build/ui/plugins/sentry.client.ts";
import cookies_client_Rz3izNprJ6 from "/opt/atlassian/pipelines/agent/build/ui/plugins/cookies.client.ts";
import plugin_client_XzbXEIMOn1 from "/opt/atlassian/pipelines/agent/build/ui/data/plugin.client.ts";
import plugin_sBWeF8A7jb from "/opt/atlassian/pipelines/agent/build/ui/cart/plugin.ts";
import plugin_client_jJwaFRiDDy from "/opt/atlassian/pipelines/agent/build/ui/analytics/plugin.client.ts";
import plugin_OuJ5v1rpHt from "/opt/atlassian/pipelines/agent/build/ui/locale/plugin.ts";
export default [
  revive_payload_client_Frd8gASYWa,
  router_CrWB4n4PyO,
  components_plugin_KR1HBZs4kY,
  unhead_3Bi0E2Ktsf,
  prefetch_client_5ZRYOjuei0,
  i18n_huatSqtcYP,
  chunk_reload_client_kCUITUWXUN,
  configs_thizg2yOzO,
  components_m1q6StQDkO,
  vendors_E1pw69vgqi,
  router_client_W01CLn04zL,
  pages_client_aLbDq82dK3,
  checkVersion_client_yt3aQHgowY,
  errors_client_8b2hJTi7xy,
  accessibility_client_7eLzPBccuK,
  sentry_client_shVUlIjFLk,
  cookies_client_Rz3izNprJ6,
  plugin_client_XzbXEIMOn1,
  plugin_sBWeF8A7jb,
  plugin_client_jJwaFRiDDy,
  plugin_OuJ5v1rpHt
]