import { type RichText } from './types.js';

export function isRichTextEmpty(text: RichText | null | undefined) {
    if (!text) {
        return true;
    }

    if (Array.isArray(text)) {
        if (text.length === 0) {
            return true;
        }

        if (text.length === 1) {
            const firstElement = text[0];
            if (!firstElement || firstElement === null) {
                return true;
            }

            if (typeof firstElement === 'object') {
                if ('text' in firstElement && firstElement.text) {
                    return false;
                }

                if ('spans' in firstElement && firstElement.spans.length > 0) {
                    return false;
                }

                return true;
            }
        }
    }

    return false;
}
