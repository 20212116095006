export function timeout(ms: number) {
    return new Promise<void>(resolve => setTimeout(resolve, ms));
}

export function clientIdle() {
    if (typeof window === undefined) {
        return Promise.resolve();
    }

    return new Promise(resolve => {
        if ('requestIdleCallback' in window) {
            window.requestIdleCallback(resolve);
        } else {
            setTimeout(resolve);
        }
    });
}
