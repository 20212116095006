export function filterNotNull<T>(array: readonly (T | null | undefined)[]) {
    return array.filter(x => x != null) as T[];
}

export function mapNotNull<T1, T2>(array: readonly T1[], map: (item: T1) => T2 | undefined | null) {
    const result: T2[] = [];
    for (const item of array) {
        const mapped = map(item);
        if (mapped != null) {
            result.push(mapped);
        }
    }
    return result;
}

export function findMin<T>(array: readonly T[], value: (item: T) => number) {
    let min: T | undefined;
    for (const item of array) {
        if (!min) {
            min = item;
        } else if (value(item) < value(min)) {
            min = item;
        }
    }

    return min;
}

export function findMax<T>(array: readonly T[], value: (item: T) => number) {
    let max: T | undefined;
    for (const item of array) {
        if (!max) {
            max = item;
        } else if (value(item) > value(max)) {
            max = item;
        }
    }

    return max;
}
